
const currentText = ({ is_draft, tasks }) => {
    const texts = {
        drafts: {
            title: 'EmptyDraftsListTitle',
            description: 'EmptyDraftsListDescription'
        },
        trash: {
            title: 'EmptyTrashListTitle',
            description: 'EmptyTrashListDescription'
        },
        active: {
            title: 'EmptyListTitle',
            description: 'EmptyListDescription'
        }
    };


    if (tasks && tasks.deleted) return texts.trash;
    if (is_draft) return texts.drafts;

    return texts.active;
};

export default currentText;
