/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';

const TableRow = handleClick => ({ row, ...restProps }) => (
    <Table.Row
        {...restProps}
        onClick={() => handleClick(row)}
        style={{ cursor: 'pointer' }}
    />
);

export default TableRow;
