import React, { Fragment } from 'react';

import DeleteTask from './DeleteTask';

const TableTools = ({ rowsSelected, actions }) => (
    <Fragment>
        {(rowsSelected || []).length ? <DeleteTask rowsSelected={rowsSelected} actions={actions} /> : null}
    </Fragment>
);

export default TableTools;
