import qs from 'qs';

export default {
    dataURL: 'registers/keys',
    sourceName: 'registryHistoryList',
    getDataUrl: (dataURL, { page, rowsPerPage, filters: { keyId } }, useQueryParams = true) => {
        const offset = ((page || 1) - 1) * rowsPerPage;

        const queryString = qs.stringify({ offset, limit: rowsPerPage }, { arrayFormat: 'index' });

        return `${dataURL}/${keyId}/history` + ((useQueryParams && queryString) ? '?' + queryString : '');
    },
    mapData: (payload) => {
        const { meta } = payload;
        const { limit, count, offset } = meta || {};

        return {
            data: payload,
            page: Math.ceil(offset / limit),
            rowsPerPage: limit,
            count
        };
    }
};
